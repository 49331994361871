import React from 'react';
import Grid from '@material-ui/core/Grid';
import { string } from 'prop-types';

const Header1 = ({ title, headerContainer }) => (
  <Grid container style={headerContainer}>
    <Grid sm={8} item>
      <h1>{title}</h1>
    </Grid>
  </Grid>
);

Header1.propTypes = {
  title: string.isRequired,
};

export default Header1;
